import styled from "styled-components"
import { DEVICES } from "../../../assets/constants/DEVICES"

const ListOfArticles = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
`

export { ListOfArticles }
